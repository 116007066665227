import React, {useState} from 'react';
import {Link} from 'gatsby';
import Header from '../components/Header';
import Footer from '../components/Footer';
import { Helmet } from "react-helmet"
import axios from 'axios';
import { useEffect } from 'react';
// geolocalization: read loc field from here https://www.cloudflare.com/cdn-cgi/trace


export default function Pricing () {
  const [currency, setCurrency] = useState('eur');


  useEffect(async () => {
    //const location = await axios.get('https://geolocation.algorithma.workers.dev/');
    const location = await axios.get('https://geolocation.skillmeter.com');
    
    if (location && location.data.continent != 'EU') setCurrency('usd');
    //console.log(location.data.continent);
    //console.log(location);

  }, []);

    return (

        <>
        <Helmet>
            <meta charSet="utf-8" />
            <title>Plans &amp; Pricing - 14 Day Free Trial | Skillmeter</title>
            <meta name="description" content="See pricing for Skillmeter and sign up to start hiring faster and better." />
            <meta name="keywords" content="candidate testing, online testing, online assessment, skillmeter pricing" />

        </Helmet>

        <Header />

        <section className="py-12 bg-gray-50 sm:py-16 lg:py-20">
          <div className="px-4 mx-auto sm:px-6 lg:px-8">
            <div className="px-8 mx-auto text-center md:px-0">
              <h2 className="text-3xl font-bold text-gray-900 sm:text-4xl xl:text-5xl ">
                Honest prices, no surprises
              </h2>
              <p className="mt-6 text-lg font-normal text-gray-600 ">
              All plans free for 14 days. <br />
                Get started in less than a minute. Upgrade, downgrade, cancel at any time.
              </p>
            </div>

            <div className='max-w-sm gap-6 mx-auto mt-8 text-right md:mt-16 md:max-w-6xl'>
              <button onClick={()=>setCurrency('eur')} className={'px-2 py-1 ' + ((currency=='eur') ? 'text-white bg-smcolor rounded-xl' : '')}>EUR</button>
              <button onClick={()=>setCurrency('usd')} className={'px-2 py-1 ' + ((currency=='usd') ? 'text-white  bg-smcolor rounded-xl' : '')}>USD</button>
            </div>
            <div className="grid max-w-sm grid-cols-1 gap-6 mx-auto mt-8 text-center md:text-left md:mt-16 md:max-w-6xl md:grid-cols-3">
              <div className="overflow-hidden bg-white border border-gray-200 rounded-2xl">
                <div className="p-6 lg:px-10 lg:py-8">
                  <h3 className="text-lg font-bold text-gray-900 ">
                    Standard
                  </h3>
                  <p className="mt-3 text-5xl font-bold text-gray-900 ">
                    {currency === 'usd' ? '$59' : '€49'} <span className='text-lg'>/ month</span>
                  </p>
                  <a href={process.env.SIGNUP_URL + '?plan=1&currency=' + currency} title="Get started" className="inline-flex items-center justify-center px-8 py-3.5 w-full mt-8 text-base font-bold text-gray-900 transition-all duration-200 border-2 border-gray-400 rounded-xl  focus:outline-none focus:ring-2 focus:ring-offset-2  hover:bg-gray-900 focus:bg-gray-900 hover:text-white focus:text-white " role="button">
                    Get Started
                  </a>

                  <p className="mt-8 text-base font-bold text-gray-900 ">
                    Plan includes:
                  </p>
                  <ul className="mt-4 space-y-3 text-base font-medium text-gray-600 ">
                    <li>5 users</li>
                    <li>50 candidates/month</li>
                    <li>Questionnaire tests</li>
                    <li>Private tests</li>
                    <li>Own subdomain (name.skillmeter.com)</li>

                  </ul>
                </div>
              </div>

              <div className="relative">
                <div className="absolute -inset-4">
                  <div className="w-full h-full mx-auto rotate-180 opacity-30 blur-lg filter" ></div>
                </div>

                <div className="relative overflow-hidden bg-white border border-gray-200 rounded-2xl">
                  <div className="p-6 lg:px-10 lg:py-8">
                    <h3 className="text-lg font-bold text-blue-900">
                      Premium
                    </h3>
                    <p className="mt-3 text-5xl font-bold text-blue-900">
                    {currency === 'usd' ? '$119' : '€99'} <span className='text-lg'>/ month</span>
                    </p>
                    <a href={process.env.SIGNUP_URL + '?plan=2&currency=' + currency} title="Get started" className="inline-flex items-center justify-center px-8 py-3.5 w-full mt-8 text-base font-bold text-blue-900  transition-all duration-200 border-2 border-gray-400 focus:ring-offset-gray-900 rounded-xl focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-white hover:bg-opacity-90 hover:bg-blue-900 hover:text-white" role="button">
                      Get Started
                    </a>

                    <p className="mt-8 text-base font-bold text-blue-900">
                      Plan includes:
                    </p>
                    <ul className="mt-4 space-y-3 text-base font-medium text-blue-900">
                      <li>10 users</li>
                      <li>100 candidates/month</li>
                      <li>Questionnaire tests</li>
                      <li>Private tests</li>
                      <li>Own subdomain (name.skillmeter.com)</li>
                      <li>&nbsp;</li>

                    </ul>
                  </div>
                </div>
              </div>

              <div className="overflow-hidden bg-white border border-gray-200 rounded-2xl">
                <div className="p-6 lg:px-10 lg:py-8">
                  <h3 className="text-lg font-bold text-green-900 ">
                    Ultimate
                  </h3>
                  <p className="mt-3 text-5xl font-bold text-green-900 ">
                  {currency === 'usd' ? '$239' : '€199'} <span className='text-lg'>/ month</span>
                  </p>
                  <a href={process.env.SIGNUP_URL + '?plan=3&currency=' + currency} title="Get started" className="inline-flex items-center justify-center px-8 py-3.5 w-full mt-8 text-base font-bold text-green-900 transition-all duration-200 border-2 border-gray-400 rounded-xl  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900 hover:bg-green-900  hover:text-white focus:text-white " role="button">
                    Get Started
                  </a>

                  <p className="mt-8 text-base font-bold text-green-900 ">
                    Plan includes:
                  </p>
                  <ul className="mt-4 space-y-3 text-base font-medium text-green-900 ">
                      <li>Unlimited users</li>
                      <li>Unlimited candidates</li>
                      <li>Questionnaire &amp; Programming tests</li>
                      <li>Private &amp; Public tests</li>
                      <li>Own subdomain (name.skillmeter.com)</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <p className='text-center text-gray-400 mt-4'>If you are not constantly hiring then you should check the <Link to="/pricing-ondemand">on-demand plan</Link></p>
        </section>



{/** FAQ */}

<section className="py-12 bg-gray-50 sm:py-16 lg:py-20">
      <div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
        <div className="text-center">
          <h2 className="text-3xl font-bold text-gray-900 sm:text-4xl xl:text-5xl ">
            Frequently Asked Questions
          </h2>
        </div>

        <div className="grid max-w-5xl grid-cols-1 mx-auto mt-10 sm:mt-20 md:grid-cols-2 md:gap-x-16 gap-y-8 md:gap-y-12">
          <div>
            <p className="text-xl font-bold text-gray-900 sm:text-2xl ">
            Will my credit card be billed?
            </p>
            <p className="mt-3 text-base font-normal text-gray-600 sm:mt-6 sm:text-lg ">
            Cancel at any time during your trial and you won't be charged.

</p>
          </div>

          <div>
            <p className="text-xl font-bold text-gray-900 sm:text-2xl ">
            Can I change plans?           
            </p>
            <p className="mt-3 text-base font-normal text-gray-600 sm:mt-6 sm:text-lg ">
            You can change your plan at any time. All features of the new plan that you select will be effective as soon as you make the change, but your bill amount will not change until your next bill date.
            </p>
          </div>

          <div>
            <p className="text-xl font-bold text-gray-900 sm:text-2xl ">
            Can I get invoiced instead of paying through a credit card?
            </p>
            <p className="mt-3 text-base font-normal text-gray-600 sm:mt-6 sm:text-lg ">
            Absolutely. Just contact <a href="mailto:support@skillmeter.com">support</a> to get that set up.
            </p>
          </div>

          <div>
            <p className="text-xl font-bold text-gray-900 sm:text-2xl ">
            How long are your contracts?
            </p>
            <p className="mt-3 text-base font-normal text-gray-600 sm:mt-6 sm:text-lg ">
            All Skillmeter plans are month to month and you may cancel at any time. It's easy to stop your service at any time.
        </p>
          </div>

          <div>
            <p className="text-xl font-bold text-gray-900 sm:text-2xl ">
            Will my data be secured and private?
            </p>
            <p className="mt-3 text-base font-normal text-gray-600 sm:mt-6 sm:text-lg ">
            Absolutely. You access Skillmeter through a secure SSL connection. Your data is not public and can only be viewed only after you are authenticated using an encrypted connection.

</p>
          </div>

          <div>
            <p className="text-xl font-bold text-gray-900 sm:text-2xl ">
            Will Skillmeter work for any type of business?
            </p>
            <p className="mt-3 text-base font-normal text-gray-600 sm:mt-6 sm:text-lg ">
            Skillmeter works well for recruiting in IT &amp; engineering as well as any other industries. We have customers from many industries, and they're seeing great results using Skillmeter.

</p>
          </div>
        </div>
      </div>
    </section>

    



        <Footer />
        </>
    );
}